@import "../assets/font-awesome/css/font-awesome.min.css";
body {
  background-color: #f7f9fc !important;
  font-size: 14px !important;
  color: #495057 !important;
}
.user-login-wrap {
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.profile-wrap {
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 5px;
}

.profile-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.form-control {
  padding: 8px 12px;
  background-color: #fff;
  height: 40px;
  box-shadow: none !important;
  outline: none !important;
  margin-bottom: 5px;
}

.form-control:focus {
  border-color: #fd6a02 !important;
}

.drbtn {
  padding: 8px 20px;
  min-width: 100px;
  height: 40px;
  background-color: #fff !important;
  vertical-align: initial;
  border-color: #fd6a02 !important;
  color: #fd6a02 !important;
  box-shadow: none !important;
  outline: none !important;
}

.drbtn:hover {
  background-color: #fd6a02 !important;
  color: #fff !important;
}

.drbtn:active {
  background-color: #fd6a02 !important;
  color: #fff !important;
}
.form-error {
  display: block;
  margin-bottom: 5px;
  clear: both;
  overflow: hidden;
  color: red;
}
.Error {
  border-color: red;
}
.drbtn:focus {
  background-color: transparent !important;
  color: #fd6a02 !important;
}

header.main-header {
  position: fixed;
  width: 100%;
  background-color: transparent;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 1;
  background-color: #293042;
}

header.main-header .nav-item .nav-link {
  color: #fff;
  font-size: 16px;
  padding: 0;
}

header.main-header .nav-item .dropdown-menu {
  position: absolute !important;
  padding: 5px 0;
  right: 0;
  left: auto;
  display: block;
  border: none;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.12);
  transition: 0.5s;
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
}

header.main-header .nav-item .dropdown-menu.show {
  animation-name: dropdownAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

header.main-header .nav-item .dropdown-menu .dropdown-item:focus {
  background-color: #f8f9fa !important;
}

header.main-header .nav-item .dropdown-menu a {
  padding: 8px 15px;
}

header.main-header .nav-item .dropdown-menu a img {
  margin-right: 5px;
}

header.main-header .nav-item .dropdown-toggle:after {
  border: solid;
  border-width: 0 1px 1px 0;
  padding: 3px;
  transform: rotate(45deg);
}

aside.main-nav {
  background-color: #293042;
  position: fixed;
  top: 0;
  padding-top: 74px;
  width: 180px;
  height: 100%;
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.12);
  overflow: auto;
  padding-bottom: 30px;
}

aside.main-nav .sidebar .menu-list li .list-item {
  text-decoration: none !important;
  text-transform: capitalize;
  display: block;
  color: #fff;
  font-size: 16px;
  background-color: transparent;
  border: none !important;
  width: 100%;
  outline: none !important;
  text-align: left;
  padding: 10px 15px;
}

aside.main-nav .sidebar .menu-list li .active {
  background-color: #fd6a02;
}

aside.main-nav .sidebar .menu-list li .list-item img {
  filter: brightness(0) invert(1);
}

aside.main-nav .sidebar .menu-list li .sunbmenu {
  background: rgba(255, 255, 255, 0.1);
  padding: 10px 0;
}

aside.main-nav .sidebar .menu-list li .sunbmenu li a {
  font-size: 16px;
  display: block;
  background-color: transparent;
  border: none;
  width: 100%;
  color: #fff;
  padding: 10px 30px;
  text-decoration: none !important;
  text-transform: capitalize;
}

aside.main-nav .sidebar .menu-list li .sunbmenu li.active a {
  color: #fd6a02;
  font-weight: 600;
}

.router-link-active {
  color: #fd6a02 !important;
  font-weight: 600 !important;
}

.nav-btn .router-link-active {
  color: #fff !important;
  font-weight: 600 !important;
  background-color: #fd6a02 !important;
}

.main-contant {
  width: calc(100% - 180px);
  margin-left: auto;
  padding: 90px 20px 12px;
}

.page-title {
  font-size: calc(1vw + 10px);
  text-transform: capitalize;
  margin-bottom: 20px;
}

.card-wrap {
  box-shadow: 3px 0 10px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 5px;
  padding: 15px;
}

.card-wrap table {
  font-size: 15px;
}

.card-wrap table thead {
  background-color: #f7f9fc;
}

.card-wrap table th {
  text-transform: capitalize;
}

.card-wrap table td,
.card-wrap table th {
  color: #495057;
  max-width: 350px;
  border: none;
  min-width: 150px;
}

.card-wrap table td a,
.card-wrap table th a {
  font-weight: 600;
  text-decoration: none !important;
  color: #3f80ea;
}

.card-wrap .profile-wrap {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 3px;
}

.card-wrap .profile-wrap img {
  border-radius: 3px;
}

label {
  font-size: 16px;
  text-transform: capitalize;
}

.add-question-wrap {
  max-width: 750px;
  margin: 0 auto;
  padding: 50px;
}

.profile_wrap .form-group {
  clear: both;
  display: block;
  margin: 0 -10px;
}

.profile_wrap .form-group label {
  width: 150px;
  float: left;
  padding: 10px;
}

.profile_wrap .form-group .input-wrap {
  float: left;
  padding: 10px;
  width: calc(100% - 150px);
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
}

@media (max-width: 991px) {
  .add-question-wrap {
    padding: 30px;
  }
  .secrch-btn,
  .secrch-input {
    float: inherit !important;
    width: 100% !important;
    margin: 3px 0;
  }
  .secrch-btn .btn,
  .secrch-input .btn {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .secrch-wrap .list-inline-item {
    width: auto;
    display: inline-block;
  }
  .list-inline-item {
    float: initial !important;
    width: 100%;
    display: block;
  }
  .list-inline-item .btn {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
  }
  .block-ui-overlay{
    opacity: 0.1 !important;
  }
  .page-title {
    font-size: calc(2vw + 10px);
  }
  .mini-logo-bar {
    position: fixed;
    top: 0;
    width: 100%;
    background: #293042;
    z-index: 9999999;
  }
  aside.main-nav {
    width: 100%;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .main-contant {
    width: 100%;
  }
  body::after {
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    position: fixed;
    content: "";
    left: 0;
    top: 0;
    transition: 0.5s;
  }
  .opne-menu {
    overflow: hidden;
  }
  .opne-menu aside.main-nav {
    transition: 0.2s;
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
  }
  .opne-menu::after {
    opacity: 0.5;
    visibility: visible;
  }
  .profile_wrap .form-group label {
    width: 100%;
  }
  .profile_wrap .form-group .input-wrap {
    width: calc(100%);
  }
  .user-login-wrap .form-control {
    min-width: 100%;
  }
}

/* .main-header .dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  overflow: visible;
  animation-name: dropdownAnimation;
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  transform: translateY(0);
  visibility: visible;
  pointer-events: initial;
} */

.nav-item.dropdown.b-dropdown button {
  background: transparent !important;
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
  padding-left: 2px !important;
}

.paginations {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.3px;
  color: #052238;
  text-align: right;
}
.paginations p {
  margin: 0;
  display: inline-block;
  margin-right: 30px;
}
.paginations p .fa.fa-angle-left,
.paginations p .fa.fa-angle-right {
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 5px;
}

.paginations p .fa:hover {
  color: #3581f7;
  font-weight: bold;
}

.paginations p span {
  padding: 5px;
}

.paginations select {
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}

.question-head {
  /*max-width: 700px;*/
  color: #3f80ea;
  font-size: calc(0.8vw + 10px);
}
.custom-radio {
  position: relative;
  padding-left: 26px;
  /*margin-right: 30px;*/
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #464646;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.custom-radio .checkbox {
  position: absolute;
  top: 1px;
  left: 0;
  height: 19px;
  width: 19px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid #d2d2d2;
}
.custom-radio .checkbox:after {
  content: "";
  position: absolute;
  transition: 0.5s;
  transform: scale(0);
}
.custom-radio input:checked ~ .checkbox {
  border-color: #293042;
}
.custom-radio input:checked ~ label {
  font-weight: 500;
}
.custom-radio input:checked ~ .checkbox:after {
  transform: scale(0.8);
}
.custom-radio .checkbox:after {
  top: 1px;
  left: 1px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #293042;
}
.question-form ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 25px;
}
.question-form ul li {
  margin-bottom: 6px;
}
.question-form label {
  font-size: 14px;
  color: #293042;
  display: block;
  margin-bottom: 10px;
}

.filterbtn {
  margin-top: -6px;
}

.forgotpass {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 141.68%;
  letter-spacing: 0.02em;
  color: #3581f7;
  display: block;
  text-align: center;
  margin: 20px;
}

.report-btn {
  width: 100%;
}

@media (max-width: 991px) {
  .report-btn {
    margin-top: 10px;
  }
}
@media (max-width: 576px) {
  .earningButtons{
    margin-bottom: 16px;
  }
}
